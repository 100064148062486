/* import Alert from '../components/alert' */
import Footer from '../components/footer'
import Meta from '../components/meta'
/* import Header from '../components/header' */
import { useEffect } from 'react';

export default function Layout({ preview, children, siteEnv }) {


  return (
    <>
      <Meta siteEnv={siteEnv}/>
      <div className="min-h-screen">
        <main className="overflow-hidden">{children}</main>
      <Footer />
      </div>
    </>
  )
}

