import Container from './container'
import Link from 'next/link'

export default function Footer() {
  return (
    <footer className="bg-color-dark-grey py-8 text-white">
      <Container>
        <div className="grid grid-cols-12 gap-4 mb-4 justify-between items-center">
          <div className="col-span-12 lg:col-start-3 lg:col-span-8">
            <ul>
              <li className="text-color-primary">Vous avez une question ?</li>
              <li className="text-color-primary">
                <Link href="tel:014-770-1974">
                  <a className="flex align-center">
                    <i className="fas svg-img fa-phone mr-2"></i>01 47 70 19 74
                  </a>
                </Link>
              </li>
              <li className="text-color-primary">
                <Link href="/contact">
                  <a className="flex align-center "><i className="fas svg-img fa-envelope mr-2"></i>Contactez-nous</a>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-4 justify-between items-center">
          <div className="col-span-12 lg:col-span-4 lg:col-start-3">
            <p>© Progressive-web-apps 2016 - 2017 | <Link href="/mentions-legales"><a className="text-color-primary">Mentions légales</a></Link></p>
          </div>
          <div className="col-span-4 text-right lg:d-none">
            <p>Version 1.0.21</p>
          </div>
        </div>
       
      </Container>
    </footer>
  )
}
