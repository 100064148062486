import dynamic from 'next/dynamic'

const Footer = dynamic(() => import('../components/footer-alphalives'))
const FooterPWA = dynamic(() => import('../components/footer'))
const Meta = dynamic(() => import('../components/meta'))
const Header = dynamic(() => import('../components/header-menu'))

export default function LayoutAlpha({ preview, children, siteEnv, isIndex, breadcrumbs, dontShowFooter =false }) {

  return (
    <>
      <Meta siteEnv={siteEnv} />
      {siteEnv == 'alphalives' && <Header isIndex={isIndex} breadcrumbs={breadcrumbs} />}
        
      <div className="min-h-screen">
        <main className="overflow-hidden">{children}</main>
        
        {siteEnv == 'alphalives' && (dontShowFooter == false && <Footer isIndex={isIndex} />)}
        {siteEnv == 'pwafr' && <FooterPWA />}
      </div>
    </>
  )
}
