import Link from 'next/link'
import Image from 'next/image'

export default function Header() {
  return (
    <div className="bg-color-dark-grey relative z-20">
      <div className="container px-5 mx-auto grid grid-cols-12 gap-4 ">
        <nav className="flex justify-start items-center col-span-10 col-start-3">
          <div className="block pr-4">
            <Link href="/">
              <a>
                <Image height={59} width={59} src="/icons/logo.png" alt='agence web Alphalives' />
              </a>
            </Link>
          </div>
          <div className="nav-item active">
            <Link href="/">
              <a>
                Accueil
              </a>
            </Link>
          </div>
  
          <div className="nav-item">
            <Link href="/contact">
              <a>
                Contact
              </a>
            </Link>
          </div>
    
        </nav>
      </div>
    </div>
  )
}
